<!-- scheduler-popup.component.html -->
<div *ngIf="scheduleForm" [formGroup]="scheduleForm" class="popup-container">
  <div class="popup-header">
    Scheduler
    <em (click)="closeDialog()" class="closeSearch fa fa-close float-right"></em>
  </div>

  <div class="row radioBtnForm">
    <mat-radio-group formControlName="scheduleType">
      <mat-radio-button value="oneTime">One Time Schedule</mat-radio-button>
      <mat-radio-button value="recurring">Recurring Schedule</mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="popup-form mt-4">
    <!-- Show/hide controls based on the selected schedule type -->
    <div *ngIf="schedulerFormFlagOneTime">
      <mat-form-field appearance="standard">
        <mat-label>Date Time Picker:</mat-label>
        <input matInput type="datetime-local" formControlName="dateTime">
        <mat-error *ngIf="scheduleForm?.get('dateTime')?.hasError('required')">Date and time is required</mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="!schedulerFormFlagOneTime" class="input-container">
      <mat-form-field appearance="standard">
        <mat-label>Minutes:</mat-label>
        <input matInput formControlName="minute">
        <mat-hint class="hint">Cron Minutes to Run. Use "*" for "all". (Example: "0,30")</mat-hint>
        <mat-error *ngIf="scheduleForm?.get('minute')?.hasError('required')">Minute is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>Hours:</mat-label>
        <input matInput  formControlName="hour">
        <mat-hint class="hint">Cron Hours to Run. Use "*" for "all". (Example: "8,20")</mat-hint>
        <mat-error *ngIf="scheduleForm?.get('hour')?.hasError('required')">Hour is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>Days Of week:</mat-label>
        <input matInput  formControlName="day_of_week">
        <mat-hint class="hint">Cron Days Of The Week to Run. Use "*" for "all", Sunday is 0 or 7, Monday is 1. (Example: "0,5")</mat-hint>
        <mat-error *ngIf="scheduleForm?.get('day_of_week')?.hasError('required')">Day of week is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>Days Of Month:</mat-label>
        <input matInput formControlName="day_of_month">
        <mat-hint class="hint">Cron Days Of The Month to Run. Use "*" for "all". (Example: "1,15")</mat-hint>
        <mat-error *ngIf="scheduleForm?.get('day_of_month')?.hasError('required')">Day of month is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>Month Of Year:</mat-label>
        <input matInput  formControlName="month_of_year">
        <mat-hint class="hint">Cron Months (1-12) Of The Year to Run. Use "*" for "all". (Example: "1,12")</mat-hint>
        <mat-error *ngIf="scheduleForm?.get('month_of_year')?.hasError('required')">Month of year is required</mat-error>
      </mat-form-field>

      <!-- Add other recurring schedule options (Days of week, days of month, etc.) -->

      <mat-form-field appearance="standard">
        <mat-label>Cron Timezone:</mat-label>
        <mat-select formControlName="timezone">
          <mat-option value="UST">UST</mat-option>
          <!-- Add more options as needed -->
        </mat-select>
        <mat-error *ngIf="scheduleForm?.get('timezone')?.hasError('required')">Timezone is required</mat-error>
      </mat-form-field>
    </div>

    <!-- Add a submit button -->
    <div>
    <button class="custom-btn-md mx-2 btnSubmit btn-height float-right" type="button" (click)="openSchedulerModal()">Submit</button>
    </div>
  </div>
</div>
