<span class="confirmImg"> <img src="assets/images/icons/checked.png" alt="" /></span>
<span class="confirmHeading mt-2 mb-2"><span>Success!</span></span>
<div
  mat-dialog-content
  class="confirmText"
  [innerHTML]="data.confirmationMsg"
></div>
<div mat-dialog-actions class="actionBtn mt-2">
  <button class="confirmBtn" mat-button mat-dialog-close>Ok, Continue</button>
</div>
