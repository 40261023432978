import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss']
})
export class SchedulerComponent implements OnInit {

  scheduleForm?: FormGroup | undefined;
  schedulerFormFlagOneTime:boolean = true;
  

  constructor(private fb: FormBuilder,private mdDialogRef: MatDialogRef<SchedulerComponent>) {}

  ngOnInit() {
    this.scheduleForm = this.fb.group({
      is_recurring_schedule: [false, Validators.required],
      scheduleType: ['oneTime', Validators.required],
      dateTime: ['', Validators.required],
      minute: [''],
      hour: [''],
      day_of_week: [''],
      day_of_month: [''],
      month_of_year: [''],
      timezone: ['UST', Validators.required]
    });

    // Set up conditional validation
    this.scheduleForm?.get('scheduleType')?.valueChanges.subscribe((value:any) => {
      this.setValidatorsBasedOnType(value);
    });
  }

  setValidatorsBasedOnType(scheduleType: string) {
    const dateTimeControl = this.scheduleForm?.get('dateTime');
    const minutesControl = this.scheduleForm?.get('minutes');
    const hoursControl = this.scheduleForm?.get('hours');
    const dayOfWeekControl = this.scheduleForm?.get('dayOfWeek');
    const daysOfMonthControl = this.scheduleForm?.get('daysOfMonth');
    const monthsOfYearControl = this.scheduleForm?.get('monthsOfYear');

    // Clear existing validators
    dateTimeControl?.clearValidators();
    minutesControl?.clearValidators();
    hoursControl?.clearValidators();
    dayOfWeekControl?.clearValidators();
    daysOfMonthControl?.clearValidators();
    monthsOfYearControl?.clearValidators();

    if (scheduleType === 'oneTime') {
      // Set validators for one-time schedule
      dateTimeControl?.setValidators([Validators.required]);
      this.schedulerFormFlagOneTime = true;
      this.scheduleForm?.get('is_recurring_schedule')?.setValue(false);
    } else if (scheduleType === 'recurring') {

      this.schedulerFormFlagOneTime = false;
      this.scheduleForm?.get('is_recurring_schedule')?.setValue(true);
      // Set validators for recurring schedule
      minutesControl?.setValidators([Validators.required]);
      hoursControl?.setValidators([Validators.required]);
      dayOfWeekControl?.setValidators([Validators.required]);
      daysOfMonthControl?.setValidators([Validators.required]);
      monthsOfYearControl?.setValidators([Validators.required]);
      
    }

    // Update the controls' validators
    dateTimeControl?.updateValueAndValidity();
    minutesControl?.updateValueAndValidity();
    hoursControl?.updateValueAndValidity();
    dayOfWeekControl?.updateValueAndValidity();
    daysOfMonthControl?.updateValueAndValidity();
    monthsOfYearControl?.updateValueAndValidity();
  }

  openSchedulerModal() {
    if (this.scheduleForm?.valid) {
      const formData:any = {};

    Object.keys(this.scheduleForm.controls).forEach((controlName:any) => {
      const control = this.scheduleForm?.get(controlName);
      if (control?.value !== null && control?.value !== undefined && control.value !== '') {
        formData[controlName] = control.value;
      }
    });

    delete formData['scheduleType'];
    delete formData['timezone'];
    
      this.mdDialogRef.close(formData)
    } else {
      // Handle invalid form
      console.log("Invalid form");
    }
  }

  closeDialog(){
    this.mdDialogRef.close();
  }
}